import React from "react"
import { Link } from 'gatsby'
import KimbleVizGalleryImage from "../../../components/KimbleVizGalleryImage"
import Helmet from '../../../components/Helmet';
import Header from '../../../components/Header';
import Footer from '../../../components/Footer';
import OliveGreenCube from "../../../images/svg/OLIVE_GREEN_cube.inline.svg"

import "../../../components/ContentBlogStyles.scss"

const KimbleVizGallery = () => {
  return (
    <>
      <Helmet title="Gallery" />
      <Header subNav={true} />

      <main id="gallery">
        <div className="container">
          <section className="section container">
            <div className="content" id="main-content">
              <section className="blog">
                <div className="blog-header">
                  <div className="top-title">
                    <div>
                      <OliveGreenCube aria-hidden="true" />
                      <h1 className="h1">
                        <span>Gallery</span>
                      </h1>
                    </div>
                    <p>
                      Everyone knows the "picture is worth a thousand words" phrase. It's true. Not only that&mdash;as mentioned in other blog posts&mdash;having a vision of the future is key to measuring and meeting expectations. How do you know what it looks like? How will it work? How will you interact with it? Will we both agree on this part? Is it the right size or shape? Will other parts interfere? Finally, how can I get a grasp conceptually of the whole of this object to understand better it's context and placement? Well, after you scratch on paper you build it in 3D that's how!  
                    </p>
                  </div>
                  <div className="global-tags tags-header">
                    <h2 className="h1">
                      <span>Overland 3D Visualization Pics</span>
                    </h2>
                    <h3 className="h3"><Link to="/blog/gallery/kimble-build-gallery">View the build gallery</Link></h3>
                    <p>In this gallery you'll find early ideas, final ideas, interiors, exteriors, and experiments. Making it come to life is the exciting part. With 3D you can literally see the future.</p>
                    <div className="work-tags blog-tags"></div>
                  </div>
                </div>
              </section>

              <section className="blog-summary">
                <KimbleVizGalleryImage />
              </section>
            </div>
          </section>
        </div>
      </main>
      <Footer />
    </>
  )
}

export default KimbleVizGallery
